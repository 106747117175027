<template>
	<div class='h100 displayFlex flexColumn'>
		<NavbarVue :showBack="true" backgroundColor="#FF9941" color="white">Pending Tasks Detail</NavbarVue>
		<div class='displayFlex flexColumn p20px' :style="{height: Height}">
			<!-- 上 pdf预览 -->
			<div class="h50 OverflowHidden">
				<p style="color: #FF9941;text-align: right;" class="pointer" @click="previewPdf">Review InFP</p>
				<PreviewPdf width="50%"></PreviewPdf>
			</div>
			<!-- 下  -->
			<div class="h50 p10px OverflowAuto">
				<!-- To be completed if call back is required -->
				<MyTitle Title="To be completed if call back is required" class="mb10px"></MyTitle>
				<span class="mr10px">Call back is required for:</span>
				<CheckBox checkBoxType="multipleNoBorder" :content="checkBoxContent" @changeStatus="changeStatus" class="displayInlineBlock" />
				<el-form :model="params" ref="personalForm1" class="mt20px my-form" label-position="left">
					<el-row>
						<el-col :span="12">
							<el-form-item label="Date of call (DD/MM/YYYY):">
								<el-date-picker v-model="params.call_date" type="date" format="dd/MM/yyyy" value-format="yyyy-MM-dd" placeholder="Select date" />
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Time of call(am/pm):">
								<el-input v-model="params.call_time" placeholder="Please input" @input="params.call_time = $util.inputReg(params.call_time)"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Phone no. userd for call back:">
								<el-input v-model="params.call_back_tel" placeholder="Please input" @input="params.call_back_tel = $util.inputReg(params.call_back_tel)"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="24">
							<el-form-item label="Feedback (if any) is gathered on the sales process and quality of advice provided by the representative:">
								<el-input v-model="params.representative_advice" type="textarea" placeholder="Notes" style="margin-top: 35px;" @input="params.representative_advice = $util.inputReg(params.representative_advice)"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<!-- To be completed for ILP/CIS/Securities application -->
				<MyTitle Title="To be completed for ILP/CIS/Securities application" class="mb10px"></MyTitle>
				<span class="mb10px">Fulfils customer knowledge assessment criteria:</span>
				<SwitchBox class="mt10px" :status.sync="params.fulfills_cka_criteria" width="300px"></SwitchBox>
				<p>Client's risk profile:</p>
				<el-radio-group v-model="params.client_risk_profile" class="my-radio">
					<el-radio v-for="item in checkBoxRisk" :label="item.value" :key="item.value">{{item.label}}</el-radio>
				</el-radio-group>
				<p>I have reviewed the ILP/CIS/Securities application and noted that:</p>
				<el-radio-group v-model="params.is_required_smc" class="my-radio">
					<el-radio v-for="item in checkBoxNote" :label="item.value" :key="item.value">{{item.label}}</el-radio>
				</el-radio-group>
				<p>Comments</p>
				<el-input v-model="params.application_comments" type="textarea" placeholder="Notes" style="margin-top: 10px;width: 90%;" @input="params.application_comments = $util.inputReg(params.application_comments)"></el-input>
				<p>Based on the information gathered,</p>
				<el-radio-group v-model="params.is_agree" class="my-radio">
					<el-radio label="Y" style="width: 100%;">
						<p class="displayInlineBlock">I agree with the recommendation made by the representative; the MFP is completed to my satisfaction in accordance to the following:</p>
						<p class="grey">a)The needs analysis has taken into account the information disclosed by the client.</p>
						<p class="grey">b)The reasons of recommendation are written clearly and framed in the context of the client’s situation addressing client’s
							financial objectives and concerns.</p>
						<p class="grey">c) The basis and implications for replacement of policy has been duly explained to client and documented, when applicable.</p>
						<p class="grey">d) All dates and signature in the MFP, Policy Illustration(s), Cover Page (if applicable), Bundled Product Disclosure (if applicable)
							and Proposal forms are in order.</p>
					</el-radio>
					<el-radio label="N" style="width: 100%;">
						<p class="displayInlineBlock">I disagree with the recommendation made by the representative.</p>
					</el-radio>
				</el-radio-group>
				<p>Reason(s)</p>
				<el-input v-model="params.supervisor_comments" type="textarea" placeholder="Notes" style="margin-top: 10px;" @input="params.supervisor_comments = $util.inputReg(params.supervisor_comments)"></el-input>
			</div>
		</div>
		<!-- 固定按钮 -->
		<div class="displayFlex h60px pointer" style="border-top: 1px solid;">
			<div @click="submit('40')" class="w50 h60px displayFlex flexAlignCenter flexJCCenter" style="color: red;">Reject</div>
			<div @click="submit('30')" class="w50 h60px displayFlex flexAlignCenter flexJCCenter" style="background: #8FA5B4;color: white;">Approve</div>
		</div>
		<!-- sign -->
		<sign-dialog :kycPDFLink="kycPDFLink" :Dialog="Dialog"></sign-dialog>
	</div>
</template>

<script>
import NavbarVue from "@/components/Navbar.vue";
import { GET_username } from '@/utils/localStorage';
import MyTitle from '../KYC/components/MyTitle.vue';
import CheckBox from '../KYC/components/checkBox.vue';
import SwitchBox from '@/components/switchBox.vue';
import PreviewPdf from "../KYC/components/PreviewPdf.vue";
import { verifyKycRepeated, approval } from "@/api/KYC/index"
import SignDialog from '../KYC/components/signDialog.vue';
export default {
	components: { NavbarVue, MyTitle, CheckBox, SwitchBox, PreviewPdf, SignDialog },
	data () {
		return {
			tableData: [],
			inputSearch: '',
			// pages分页
			pages: {
				total: 0,
				pageNum: 1,
				pageSize: 10,
			},
			params: {
				kyc_no: this.$router.currentRoute.query.kyc_no,
				call_back_client: '',
				call_back_representative: '',
				call_date: '',
				call_time: '',
				call_back_tel: "",
				representative_advice: '',
				fulfills_cka_criteria: '',
				client_risk_profile: "",
				is_required_smc: "",
				application_comments: "",
				audit_result: '',
				is_agree: '',
				supervisor_comments: '',
				supervisor_name: this.$store.state.User.Rep.rep_name,
				supervisor_code: GET_username()
			},
			checkBoxContent: [
				{ label: 'Selected client', value: 'call_back_client' },
				{ label: 'Selected representative', value: 'call_back_representative' },
			],
			checkBoxRisk: [
				{ label: 'Conservative', value: '1' },
				{ label: 'Moderately conservative', value: '2' },
				{ label: 'Balanced', value: '3' },
				{ label: 'Moderately Aggressive', value: '4' },
				{ label: 'Aggressive', value: '5' },
			],
			checkBoxNote: [
				{ label: 'SMC is not required:', value: 'Y' },
				{ label: 'SMC is required(Please submit SMC form together with the application)', value: 'N' }
			],
			Height: '',
			Dialog: {
				visible: false,
				status: "Add"
			},
			kycPDFLink: ''
		};
	},
	computed: {},
	watch: {},
	methods: {
		changeStatus (val) {
			this.params.call_back_client = ''
			this.params.call_back_representative = ''
			val.map(res => {
				this.params[res.value] = 'Y'
			})
		},
		getHeight () {
			this.Height = (window.innerHeight - 170) + 'px'
		},
		// 提交
		async submit (result) {
			this.params.audit_result = result
			if (!this.params.is_agree) {
				let mes = result === '40' ? "Please confirm you are rejecting this application.Select the box 'I disagree' and enter the reason(s)." :
					"Please confirm you are approving this application.Select the box 'I agree'."
				return this.alertPop(mes)
			}
			if (this.params.is_agree === 'Y' && result === '40' || this.params.is_agree === 'N' && result === '30') {
				return false
			}
			if (this.params.is_agree === 'N' && result === '40' && !this.params.supervisor_comments) {
				return this.alertPop('Please enter your reason(s) in the box below.')
			}
			let verify = await verifyKycRepeated({ kyc_no: this.$router.currentRoute.query.kyc_no, agent_id: GET_username() })
			if (verify.content.old_kyc_no) {
				this.alertPop('The same KYC already exists. Are you sure to replace it?', 2)
			} else {
				this.alertPop(result === '40' ?'Please confirm that you are rejecting this application.' : 'Please confirm that you are approving this application.', 2)
			}
		},
		alertPop (message, type) {
			if (type === 2) {
				this.$confirm(message, 'Alert', {
					confirmButtonText: 'Confirm',
					cancelButtonText: 'Cancel',
					type: "info",
				}).then(() => {
					this.submitAPI()
				}).catch(() => { })
			} else {
				this.$confirm(message, 'Alert', {
					cancelButtonText: 'Confirm',
					showConfirmButton: false
				}).catch(() => { })
			}
		},
		submitAPI () {
			// 提交逻辑
			approval(this.params).then(data => {
				if (data.redirectURL || data.content) {
					// 跳转到签字页面
					this.kycPDFLink = data.content
					this.Dialog.visible = true
				}
				if (data.rtn_code == '101' && this.params.audit_result == '40') {
					this.$confirm('Successfully rejected.', 'Tips', {
						cancelButtonText: 'OK',
						showConfirmButton: false
					}).then(() => {
						this.resetCancel()
					}).catch(() => {
						this.resetCancel()
					})
				}
			})
		},
		resetCancel() {
			this.$router.go(-1)
		},
		// 跳转pdf预览页面
		previewPdf () {
			const { href } = this.$router.resolve({
				path: '/Preview'
			});
			window.open(href, '_blank');
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		window.addEventListener('resize', this.getHeight)
		this.getHeight()
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {
	},
	activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='css' scoped>
p {
	margin: 10px 0;
}
.grey {
	color: #999;
	margin-left: 30px;
}
</style>